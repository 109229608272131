import React from 'react';
import {Toaster} from 'react-hot-toast';
import Converter from './components/Converter';
import Header from './components/Header';
import Footer from './components/Footer';

const App = () =>
  <>
    <Toaster/>
    <Header/>
    <Converter/>
    <Footer/>
  </>;

export default App;
