import React from 'react';
import LogotypeImg from '../assets/images/logotype.png';
import {Contact, COMPANY} from '../config/contact';
import '../assets/stylesheets/footer.scss';

const Footer = () =>
  <footer>
    <p className='text-center'>
      Built with <i className='fa fa-heart'/> by
      <a
        className='margin-horizontal-1'
        href={`//${Contact.web}`}
        rel='noreferrer noopener'
        target='_blank'
      >
        {Contact.name}
      </a>
        from
      <a
        href={`//${COMPANY.web}`}
        rel='noreferrer noopener'
        target='_blank'
      >
        <img
          alt={`${COMPANY.name} Logotype`}
          className='logotype'
          height={25}
          src={LogotypeImg}
          width={156}
        />
      </a>
    </p>
  </footer>;

export default Footer;
