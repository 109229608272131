import React, {useEffect, useRef, useState} from 'react';
import toast from 'react-hot-toast';
import {useToggle} from '../helpers/use_toggle';
import {decode} from 'html-entities';
import Axios from 'axios';
import {SUPPORT_EMAIL} from '../config/contact';
import API from '../config/api';
import ReactGA from 'react-ga4';
import '../assets/stylesheets/converter.scss';

const Converter = () => { // eslint-disable-line max-statements
  const [haml, setHaml] = useState('');
  const [html, setHtml] = useState('');
  const [htmlStyleOn, toggleHTMLStyle] = useToggle(false);
  const [rubyStyleOn, toggleRubyStyle] = useToggle(true);
  const [erb, toggleERB] = useToggle(true);
  const timerRef = useRef();
  const axiosRequestRef = useRef(Axios.CancelToken.source());
  const hamlTextareaRef = React.createRef();

  const copyToClipboard = () => {
    const textarea = hamlTextareaRef;
    const lastActiveElement = document.activeElement;
    textarea.current.select();
    textarea.current.setSelectionRange(0, 99999999);
    navigator.clipboard.writeText(window.getSelection().toString()).then(() => {
      toast.success('Copied to clipboard!');
    });
    textarea.current.setSelectionRange(99999999, 99999999);
    lastActiveElement.focus();
  };

  const htmlToHamlAxios = () => {
    ReactGA.event({category: 'conversion', action: 'HTML_TO_HAML'});
    Axios.post(API.HTML_TO_HAML, {
      data: html,
      options: {
        html_style_attributes: htmlStyleOn, // eslint-disable-line camelcase
        ruby19_style_attributes: rubyStyleOn, // eslint-disable-line camelcase
        erb
      }
    }, {
      cancelToken: axiosRequestRef.current.token
    }).then(res => {
      setHaml(decode(res.data.trim()));
    }).catch(err => {
      if (!Axios.isCancel(err)) {
        toast.error(err.message);
      }
    });
  };

  useEffect(() => {
    if (html.length === 0) {
      setHaml('');
    }
    else {
      setHaml(val => val.endsWith('...') ? val : `${val.trim()}...`);

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      if (axiosRequestRef.current) {
        axiosRequestRef.current.cancel();
      }

      axiosRequestRef.current = Axios.CancelToken.source();
      timerRef.current = setTimeout(htmlToHamlAxios, 500);
    }
  }, [html, htmlStyleOn, rubyStyleOn, erb, toast]);

  return (
    <>
      <div className='stripe'>
        <div className='grid-container'>
          <div className='grid-x grid-margin-x align-middle'>
            <div className='medium-shrink cell'>
              <label className='lead'>Settings:</label>
            </div>
            <div className='medium-shrink cell'>
              <input
                checked={htmlStyleOn}
                id='style-html'
                onChange={toggleHTMLStyle}
                type='checkbox'
              />
              <label htmlFor='style-html'>Use HTML Style attributes</label>
            </div>
            <div className='medium-shrink cell'>
              <input
                checked={rubyStyleOn}
                id='style-ruby'
                onChange={toggleRubyStyle}
                type='checkbox'
              />
              <label htmlFor='style-ruby'>Enable Ruby 1.9 Syntax</label>
            </div>
            <div className='medium-shrink cell'>
              <input
                checked={erb}
                id='erb'
                onChange={toggleERB}
                type='checkbox'
              />
              <label htmlFor='erb'>Enable ERB</label>
            </div>
          </div>
        </div>
      </div>
      <div className='grid-container'>
        <div className='table'>
          <div className='grid-x grid-padding-x align-middle thead'>
            <div className='auto cell'>
              <label htmlFor='html-content'>HTML</label>
            </div>
            <div className='auto cell text-right'>
              <label htmlFor='haml-content'>HAML</label>
            </div>
          </div>
          <div className='grid-x textarea'>
            <div
              className='auto cell small-order-1'
            >
              <textarea
                autoFocus
                id='html-content'
                onChange={e => setHtml(e.target.value)}
                placeholder='Enter HTML text here...'
                spellCheck={false}
                value={html}
              />
            </div>
            <div
              className='auto cell small-order-2'
            >
              <textarea
                id='haml-content'
                onChange={e => setHaml(e.target.value)}
                ref={hamlTextareaRef}
                spellCheck={false}
                value={haml}
              />
            </div>
          </div>
          <div className='grid-x grid-margin-x align-right align-middle'>
            <div className='shrink cell'>
              <a
                className='bug'
                href={`mailto:${SUPPORT_EMAIL}?subject=HTML%20to%20HAML%20Bug`}
              ><small><i
                  className='fa fa-bug fa-fw'
                /> Report a bug
                </small>
              </a>
            </div>
            <div className='shrink cell'>
              <span
                className='large round hollow copy button'
                onClick={copyToClipboard}
              >
                <i className='fa fa-copy'/>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Converter;
