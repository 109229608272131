export const Contact = {
  name: 'Vadim Kononov',
  web: 'vadimkononov.ca'
};

export const COMPANY = {
  name: 'Konoson',
  web: 'konoson.com'
};

export const SUPPORT_EMAIL = 'vadim@konoson.com';
