import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from '@rollbar/react';
import ROLLBAR_CONFIG from './config/rollbar';
import './assets/stylesheets/index.scss';
import ANALYTICS from './config/analytics';
import ReactGA from 'react-ga4';
import App from './App';

ReactGA.initialize(ANALYTICS.TRACKING_ID, {
  debug: process.env.NODE_ENV === 'development', // eslint-disable-line no-undef
  // testMode: process.env.NODE_ENV === 'development', // eslint-disable-line no-undef
  anonymizeIp: true
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<React.StrictMode>
  <Provider config={ROLLBAR_CONFIG}>
    <App/>
  </Provider>
</React.StrictMode>);
