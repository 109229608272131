import HeaderImg from '../assets/images/header.png';
import BuyMeACoffee from './BuyMeACoffee';
import React from 'react';
import '../assets/stylesheets/header.scss';

const Header = () =>
  <header className='grid-container'>
    <div className='grid-x grid-margin-x grid-margin-y align-center-middle'>
      <div className='medium-auto cell text-center medium-text-left'>
        <img
          alt='HTML to HAML'
          className='title-img'
          src={HeaderImg}
        />
      </div>
      <div className='shrink cell'>
        <div className='grid-x align-middle'>
          <div className='shrink cell text-right'>
            <div className='margin-right-1'>
              <small>Do you use<br/>this site often?</small>
            </div>
          </div>
          <div className='shrink cell'>
            <BuyMeACoffee/>
          </div>
        </div>
      </div>
    </div>
  </header>;

export default Header;
