import React from 'react';

const BuyMeACoffee = () =>
  <a
    href='https://www.buymeacoffee.com/vkononov'
    rel='noreferrer noopener'
    target='_blank'
  >
    <img
      alt='Buy Me A Coffee'
      className='buy-coffee'
      src='https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png'
    />
  </a>;

export default BuyMeACoffee;